import BasesService from 'services/basesManager.service';
import { UPDATE_BASIS_PAGE_NAMES } from 'constants/middlewares/updateBasisPageNames.const';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const basesManager = inject<BasesService>(BasesService.getServiceName());

  if (!['string', 'symbol'].includes(typeof to.name)
    || !UPDATE_BASIS_PAGE_NAMES.includes(to.name?.toString() || '')
    || (from.name && UPDATE_BASIS_PAGE_NAMES.includes(from.name?.toString() || ''))
  ) {
    return;
  }

  await basesManager?.forceCheckUserBasis();
});
